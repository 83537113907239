import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, KeepAlive as _KeepAlive } from "vue"
import _imports_0 from 'FRONTEND/images/rudolf-icon.svg'


const _hoisted_1 = { id: "topleft" }
const _hoisted_2 = { class: "hello-username" }
const _hoisted_3 = { class: "media" }
const _hoisted_4 = {
  key: 0,
  class: "media-content"
}
const _hoisted_5 = { class: "hello" }
const _hoisted_6 = { class: "user" }
const _hoisted_7 = { class: "firstname" }
const _hoisted_8 = {
  key: 0,
  class: "button-area"
}
const _hoisted_9 = { id: "topmiddle" }
const _hoisted_10 = { style: {"width":"100%","text-align":"center"} }
const _hoisted_11 = { class: "menu-container" }
const _hoisted_12 = { class: "pagetitle" }
const _hoisted_13 = { class: "dropdown-product-name" }
const _hoisted_14 = { class: "dropdown-product-name" }
const _hoisted_15 = {
  key: 1,
  class: "pagetitle"
}
const _hoisted_16 = {
  key: 2,
  class: "pagetitle"
}
const _hoisted_17 = { class: "menu-container" }
const _hoisted_18 = {
  key: 0,
  class: "pagetitle"
}
const _hoisted_19 = {
  key: 1,
  class: "pagetitle"
}
const _hoisted_20 = {
  key: 2,
  class: "pagetitle"
}
const _hoisted_21 = {
  key: 3,
  class: "pagetitle"
}
const _hoisted_22 = {
  key: 4,
  class: "pagetitle"
}
const _hoisted_23 = {
  key: 4,
  class: "pagetitle"
}
const _hoisted_24 = {
  key: 5,
  class: "pagetitle"
}
const _hoisted_25 = {
  key: 6,
  class: "pagetitle"
}
const _hoisted_26 = { id: "topright" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_router_link = _resolveComponent("router-link")
  const _component_top_nav_buttons = _resolveComponent("top-nav-buttons")

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("article", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("figure", { class: "media-left" }, [
            _createElementVNode("img", {
              class: "ml-1 mr-1",
              src: _imports_0
            })
          ], -1 /* HOISTED */)),
          (_ctx.authStore.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.l('generic/hello')), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.authStore.user.title ? `${_ctx.authStore.user.title}. ` : ''), 1 /* TEXT */),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.authStore.user.firstName || _ctx.authStore.user.fullName), 1 /* TEXT */)
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      (_ctx.rootStore.currentPage === "manage-leads")
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_ctx.authStore.user?.role !== "basic")
              ? (_openBlock(), _createBlock(_component_b_tooltip, {
                  key: 0,
                  label: _ctx.l("manage-leads/add-new-lead-tooltip"),
                  type: "is-white",
                  position: "is-bottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      class: "icon-button",
                      type: "is-light",
                      size: "is-large",
                      onClick: _cache[0] || (_cache[0] = $event => ($options.openAddLead())),
                      disabled: false,
                      "aria-controls": "add-lead-container"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("+")
                      ])),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        ([ "manage-leads", "overview" ].includes(_ctx.rootStore.currentPage))
          ? (_openBlock(), _createBlock(_component_b_dropdown, {
              key: 0,
              modelValue: $options.filteredProductId,
              "aria-role": "menu",
              "mobile-modal": false,
              scrollable: true,
              "max-height": "430px",
              onChange: _cache[1] || (_cache[1] = (productId) => $options.filterLeadsByProduct(productId))
            }, {
              trigger: _withCtx(({ active }) => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h2", _hoisted_12, _toDisplayString($options.pageTitleFull), 1 /* TEXT */),
                  _createVNode(_component_b_icon, {
                    icon: active ? 'chevron-up' : 'chevron-down',
                    "custom-size": "mdi-24px"
                  }, null, 8 /* PROPS */, ["icon"])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_b_dropdown_item, {
                  value: null,
                  "aria-role": "menuitem"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.l('generic/allproducts')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.productsSorted, (product) => {
                  return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                    key: product._id,
                    value: product._id,
                    "aria-role": "menuitem"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(product.name), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"]))
          : (_ctx.rootStore.currentPage === "activities")
            ? (_openBlock(), _createElementBlock("h2", _hoisted_15, _toDisplayString(_ctx.l('activities/pagetitle')), 1 /* TEXT */))
            : (_ctx.rootStore.currentPage === "lead-details")
              ? (_openBlock(), _createElementBlock("h2", _hoisted_16, _toDisplayString(_ctx.rootStore.openedLeadName ? _ctx.l('lead-page/pagetitle') + '-' + _ctx.rootStore.openedLeadName : 'Lead details'), 1 /* TEXT */))
              : (_ctx.rootStore.currentPage.startsWith("setup-"))
                ? (_openBlock(), _createBlock(_component_b_dropdown, {
                    key: 3,
                    "aria-role": "menu"
                  }, {
                    trigger: _withCtx(({ active }) => [
                      _createElementVNode("div", _hoisted_17, [
                        (_ctx.rootStore.currentPage === "setup-products")
                          ? (_openBlock(), _createElementBlock("h2", _hoisted_18, _toDisplayString(_ctx.l('setup-products/pagetitle')), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        (_ctx.rootStore.currentPage === "setup-users")
                          ? (_openBlock(), _createElementBlock("h2", _hoisted_19, _toDisplayString(_ctx.l('setup-users/pagetitle')), 1 /* TEXT */))
                          : (_ctx.rootStore.currentPage === "setup-data")
                            ? (_openBlock(), _createElementBlock("h2", _hoisted_20, _toDisplayString(_ctx.l('setup-data/pagetitle')), 1 /* TEXT */))
                            : (_ctx.rootStore.currentPage === "setup-labels")
                              ? (_openBlock(), _createElementBlock("h2", _hoisted_21, _toDisplayString(_ctx.l('setup-labels/pagetitle')), 1 /* TEXT */))
                              : (_ctx.rootStore.currentPage === "setup-localization")
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_22, _toDisplayString(_ctx.l('setup-localization/pagetitle')), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                        _createVNode(_component_b_icon, {
                          icon: active ? 'chevron-up' : 'chevron-down',
                          "custom-size": "mdi-24px"
                        }, null, 8 /* PROPS */, ["icon"])
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "menuitem",
                        "has-link": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: { name: "setup-products" },
                            title: "Products"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-products')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "menuitem",
                        "has-link": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: { name: "setup-users" },
                            title: "Subscription & User Role"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-users')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "menuitem",
                        "has-link": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: { name: "setup-data" },
                            title: "Data"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-data')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "menuitem",
                        "has-link": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: { name: "setup-labels" },
                            title: "Labels"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-labels')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      (_ctx.authStore.isSuperAdmin)
                        ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                            key: 0,
                            "aria-role": "menuitem",
                            "has-link": true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_router_link, {
                                to: { name: "setup-localization" },
                                title: "Localization"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-localization')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : (_ctx.rootStore.currentPage === "outlook")
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_23, "Outlook"))
                  : (_ctx.rootStore.currentPage === "account")
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_24, _toDisplayString(_ctx.l('account/pagetitle')), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("h2", _hoisted_25, "The Rudolf"))
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        _createVNode(_component_top_nav_buttons)
      ], 1024 /* DYNAMIC_SLOTS */))
    ])
  ]))
}