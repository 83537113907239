//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'
import { useToast }                 from 'vue-toastification'

import { apiRequest }               from '../api_request.js'

import router                       from '../router/index.js'

import { useAuthStore }             from './auth.js'
import { useBulkAssignStore }       from './bulkassign.js'
import { useCallListStore }         from './calllist.js'
import { useContactsStore }         from './contacts.js'
import { useExcelImportStore }      from './excelimport.js'
import { useLeadsStore }            from './leads.js'
import { useLocalizationsStore }    from './localizations.js'
import { useNotificationsStore }    from './notifications.js'
import { useProductsStore }         from './products.js'
import { useSettingsStore }         from './settings.js'
import { useTodosStore }            from './todo.js'
import { useUsersStore }            from './users.js'

import googleMapsService            from '../services/googlemaps.service.js'
import { connect } from 'socket.io-client'


let connectionLostToastId = null


export const useRootStore = defineStore('root', {
    state: () => {
        return {
            currentPage:                '',
            openedLeadName:             '',

            windowWidth:                window?.innerWidth,
    
            appAuthenticating:          true, // when app loads we're authenticating
            appLoading:                 true, // when app loads we're loading data
    
            batchUpdatesInProgress:     new Set(),
    
            addNewLeadToggled:          false,

            googleMapsLoaded:           false,
    
        }
    },


    getters: {
      isMobile() {
        return this.windowWidth <= 768
      }

    },


    actions: {
        async loadAllData() {
            const callListStore         = useCallListStore()
            const contactsStore         = useContactsStore()
            const leadsStore            = useLeadsStore()
            const notificationsStore    = useNotificationsStore()
            const productsStore         = useProductsStore()
            const settingsStore         = useSettingsStore()
            const todosStore            = useTodosStore()
            const usersStore            = useUsersStore()

            await Promise.all([
                callListStore.fetch(),
                contactsStore.fetch(),
                notificationsStore.fetch(),
                settingsStore.fetch(),
                todosStore.fetch(),
                usersStore.fetch(),
            ])

            const data = await apiRequest('GET', '/data')

            productsStore.SET(data.products)
            leadsStore.SET(data.leads)
        },


        async loadGoogleMaps() {
            await googleMapsService.init()

            this.googleMapsLoaded = true
        },


        backendConnectionLost() {
            const authStore = useAuthStore()

            if(connectionLostToastId === null && authStore.isLoggedIn) {
                const toast = useToast()

                const localizationsStore = useLocalizationsStore()

                const connectionLostMsg = localizationsStore.l('generic/connection-lost')

                connectionLostToastId = toast.error(connectionLostMsg, {
                    position: 'bottom-center',
                    timeout: false,
                    closeOnClick: false,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    draggable: false,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: false,
                    icon: false
                })
            }
        },

        backendConnectionRestored() {
            if(connectionLostToastId !== null) {
                const toast = useToast()

                toast.dismiss(connectionLostToastId)

                connectionLostToastId = null

                const localizationsStore = useLocalizationsStore()

                const connectionRestoredMsg = localizationsStore.l('generic/connection-restored')

                toast.success(connectionRestoredMsg, { position: 'bottom-center' })
            }
        },


        async eraseAllData() {
            await apiRequest('POST', '/users/deletealldata')

            this.syncEraseAllData(false, false)
        },


        async syncEraseAllData(redirectRoot = true, toastNotify = true) {
            const authStore             = useAuthStore()
            const localizationsStore    = useLocalizationsStore()
            const usersStore            = useUsersStore()

            const toast = useToast()

            try {
                const user = JSON.parse(JSON.stringify(authStore.user))

                this.appLoading = true

                this.resetStore()

                localizationsStore.SET([])
                await localizationsStore.fetch()
    
                const defaultUsers = [ 'superadmin@rudolf.com', 'admin@rudolf.com' ]
    
                if(defaultUsers.includes(user.email)) {
                    if(redirectRoot && router.currentRoute._value.name !== 'manage-leads') {
                        await router.push({ name: 'manage-leads' })
                    } else if(redirectRoot && router.currentRoute._value.name === 'manage-leads') {
                        this.currentPage = 'manage-leads'
                    }

                    await this.loadAllData()

                    if(toastNotify) {
                        toast.info('Database has been erased by superadmin', { timeout: false })
                    }
                } else {
                    usersStore.loggedInUserDeleted()
                }
    
    
            } catch(error) {

            } finally {
                this.appLoading = false
            }
        },


        resetStore(resetPageState = true) {
            const bulkAssignStore       = useBulkAssignStore()
            const callListStore         = useCallListStore()
            const contactsStore         = useContactsStore()
            const excelImportStore      = useExcelImportStore()
            const leadsStore            = useLeadsStore()
            const notificationsStore    = useNotificationsStore()
            const productsStore         = useProductsStore()
            const settingsStore         = useSettingsStore()
            const todosStore            = useTodosStore()
            const usersStore            = useUsersStore()

            callListStore.SET([])
            contactsStore.SET([])
            leadsStore.SET([])
            notificationsStore.SET([])
            productsStore.SET([])
            settingsStore.SET([])
            todosStore.SET([])
            usersStore.SET([])

            bulkAssignStore.reset()
            excelImportStore.reset()

            if(resetPageState) {
                this.currentPage = ''
                this.openedLeadName = ''
            }

            leadsStore.resetStore()
            leadsStore.resetFilters(null)
        },


        authentication() {
            return new Promise((resolve, reject) => {
                if(!this.appAuthenticating) return resolve()

                const loadingLoop = setInterval(() => {
                    if(this.appAuthenticating) return

                    clearInterval(loadingLoop)

                    resolve()
                  }, 100)
            })
        },


        batchUpdateStart(resourceType) {
            const stores = {
                leads:          useLeadsStore(),
                products:       useProductsStore(),
                users:          useUsersStore()
            }

            stores[resourceType].TOGGLE_REACTIVITY(false)

            this.batchUpdatesInProgress.add(resourceType)
        },

        batchUpdatesFinished() {
            const stores = {
                leads:          useLeadsStore(),
                products:       useProductsStore(),
                users:          useUsersStore()
            }

            for(const resourceType of this.batchUpdatesInProgress.values()) {
                stores[resourceType].TOGGLE_REACTIVITY(true)
            }

            this.batchUpdatesInProgress.clear()
        }


    }
})
