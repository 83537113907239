import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "product-setup-questions",
  style: {"position":"relative","margin-bottom":"50px"}
}
const _hoisted_2 = {
  class: "rtitle",
  style: {"margin-bottom":"24px"}
}
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = { class: "column is-6" }
const _hoisted_5 = {
  key: 0,
  class: "rtitle sm form-name"
}
const _hoisted_6 = {
  key: 1,
  class: "rtitle sm form-name"
}
const _hoisted_7 = { class: "column is-6 has-text-right" }
const _hoisted_8 = { class: "columns" }
const _hoisted_9 = { class: "column is-9" }
const _hoisted_10 = { class: "column is-3" }
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = { class: "columns" }
const _hoisted_13 = { class: "column is-9" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = { class: "has-text-right mt-4" }
const _hoisted_16 = { class: "mb-5" }
const _hoisted_17 = { class: "buttons is-justify-content-flex-end" }
const _hoisted_18 = {
  key: 1,
  class: "separator"
}
const _hoisted_19 = { class: "questions-form-bar" }
const _hoisted_20 = { class: "rtitle sm form-name" }
const _hoisted_21 = { class: "buttons" }
const _hoisted_22 = { class: "edit-form-name" }
const _hoisted_23 = { class: "save-product-name" }
const _hoisted_24 = { class: "control" }
const _hoisted_25 = { class: "control" }
const _hoisted_26 = {
  class: "mt-3 ml-1",
  style: {"display":"none"}
}
const _hoisted_27 = { class: "questions-list" }
const _hoisted_28 = {
  key: 0,
  class: "question"
}
const _hoisted_29 = { class: "question-container" }
const _hoisted_30 = {
  key: 0,
  class: "has-text-danger mt-2"
}
const _hoisted_31 = { class: "question-type-text" }
const _hoisted_32 = {
  key: 1,
  class: "slider-question",
  style: {"width":"100%"}
}
const _hoisted_33 = { class: "question-container" }
const _hoisted_34 = { class: "columns m-0" }
const _hoisted_35 = { class: "column is-6 p-0" }
const _hoisted_36 = { style: {"font-size":"0.857rem"} }
const _hoisted_37 = { class: "column is-6 p-0 has-text-right" }
const _hoisted_38 = { style: {"font-size":"0.857rem"} }
const _hoisted_39 = {
  key: 0,
  class: "has-text-danger mt-2"
}
const _hoisted_40 = { class: "question-type-text" }
const _hoisted_41 = {
  key: 1,
  class: "has-text-danger mt-2",
  style: {"font-weight":"500","padding-left":"10px"}
}
const _hoisted_42 = { class: "rudolfbox" }
const _hoisted_43 = { class: "modal-inner has-text-centered" }
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_slider = _resolveComponent("b-slider")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_radio = _resolveComponent("b-radio")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_modal = _resolveComponent("b-modal")
  const _component_change_sort_order_modal = _resolveComponent("change-sort-order-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.l('setup-questions/questions-title')), 1 /* TEXT */),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ██╗███████╗██╗    ██╗        ██╗    ███████╗██████╗ ██╗████████╗"),
    _createCommentVNode(" ████╗  ██║██╔════╝██║    ██║       ██╔╝    ██╔════╝██╔══██╗██║╚══██╔══╝"),
    _createCommentVNode(" ██╔██╗ ██║█████╗  ██║ █╗ ██║      ██╔╝     █████╗  ██║  ██║██║   ██║"),
    _createCommentVNode(" ██║╚██╗██║██╔══╝  ██║███╗██║     ██╔╝      ██╔══╝  ██║  ██║██║   ██║"),
    _createCommentVNode(" ██║ ╚████║███████╗╚███╔███╔╝    ██╔╝       ███████╗██████╔╝██║   ██║"),
    _createCommentVNode(" ╚═╝  ╚═══╝╚══════╝ ╚══╝╚══╝     ╚═╝        ╚══════╝╚═════╝ ╚═╝   ╚═╝"),
    _createCommentVNode(""),
    _createCommentVNode(" Add / edit question, collapses when add/edit button is pressed"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    ($data.editQuestionFormOpen)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "new-question-form mb-4",
          id: "add-edit-question-form",
          onSubmit: _cache[7] || (_cache[7] = _withModifiers($event => ($options.saveQuestion()), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (!$data.editQuestionId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.l('setup-questions/add-new-question')), 1 /* TEXT */))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.l('setup-questions/edit-question')), 1 /* TEXT */))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_b_field, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_checkbox, {
                    modelValue: $data.editQuestionRequired,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.editQuestionRequired) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("* " + _toDisplayString(_ctx.l('setup-questions/required-question')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "separator mb-5" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_b_field, {
                label: _ctx.l("setup-questions/question-description"),
                "label-position": "on-border"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_input, {
                    modelValue: $data.editQuestionDescription,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.editQuestionDescription) = $event)),
                    required: ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_b_dropdown, {
                modelValue: $data.editQuestionType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.editQuestionType) = $event)),
                "aria-role": "list",
                position: "is-bottom-left"
              }, {
                trigger: _withCtx(({ active }) => [
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/type"),
                    "label-position": "on-border"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_button, {
                        label: $options.getQuestionTypeStr($data.editQuestionType),
                        type: "is-select",
                        expanded: "",
                        "icon-right": active ? "menu-up" : "menu-down"
                      }, null, 8 /* PROPS */, ["label", "icon-right"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "multiple",
                    disabled: !$options.multipleTypeChangeEnabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-questions/questiontype-multiple-long')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"]),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "multiple_many",
                    disabled: !$options.multipleManyTypeChangeEnabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-questions/questiontype-multiplemany-long')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"]),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "slider",
                    disabled: !$options.sliderTypeChangeEnabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-questions/questiontype-slider-long')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"]),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "value",
                    disabled: !$options.freeTextTypeChangeEnabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-questions/questiontype-value')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"]),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "number",
                    disabled: !$options.numberTypeChangeEnabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-questions/questiontype-number')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"]),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "personal_sales",
                    disabled: !$options.personalSalesTypeChangeEnabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-questions/questiontype-personalsales-long')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createElementVNode("p", _hoisted_11, _toDisplayString($options.getQuestionTypeStr($data.editQuestionType)), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              ($data.editQuestionType === "multiple" || $data.editQuestionType === "multiple_many")
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.editQuestionMultipleChoices, (choice, idx) => {
                      return (_openBlock(), _createBlock(_component_b_field, {
                        class: "mb-2",
                        type: { "is-danger": $data.editQuestionTooFewChoicesError || $data.editQuestionDuplicateChoicesError },
                        message: idx === $data.editQuestionMultipleChoices.length - 1 ? $options.addEditQuestionMultipleOptionsErrorMessage : "",
                        grouped: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_input, {
                            modelValue: $data.editQuestionMultipleChoices[idx],
                            "onUpdate:modelValue": $event => (($data.editQuestionMultipleChoices[idx]) = $event),
                            placeholder: _ctx.l("setup-questions/question-option") + ` ${idx + 1}`,
                            expanded: ""
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                          _createElementVNode("p", _hoisted_14, [
                            _createVNode(_component_b_button, {
                              class: "icon-button",
                              type: "is-light",
                              "icon-right": "close",
                              size: "is-medium",
                              onClick: $event => ($options.removeQuestionOption(idx)),
                              disabled: !$options.canRemoveQuestionOption(idx)
                            }, null, 8 /* PROPS */, ["onClick", "disabled"])
                          ])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "message"]))
                    }), 256 /* UNKEYED_FRAGMENT */)),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_b_button, {
                        type: "is-primary",
                        rounded: "",
                        onClick: _cache[3] || (_cache[3] = $event => ($options.addQuestionOption()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.l('setup-questions/add-option')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
                : ($data.editQuestionType === "slider")
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_b_field, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_slider, {
                              class: "mt-1",
                              min: 1,
                              max: 100,
                              value: 50,
                              tooltip: false
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_b_field, {
                          class: "slider-min-max-field mt-4",
                          grouped: "",
                          "group-multiline": "",
                          type: { "is-danger": $data.editQuestionSliderMinMaxError },
                          message: $data.editQuestionSliderMinMaxError ? _ctx.l("setup-questions/slider-min-max-error") : ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_field, {
                              label: _ctx.l("setup-questions/slider-min-range"),
                              "label-position": "on-border",
                              type: { "is-danger": $data.editQuestionSliderMinMaxError }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_input, {
                                  modelValue: $data.editQuestionSliderMin,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.editQuestionSliderMin) = $event)),
                                  modelModifiers: { number: true },
                                  type: "number",
                                  min: "0",
                                  required: "",
                                  disabled: $data.editQuestionId ? true : false
                                }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label", "type"]),
                            _createVNode(_component_b_field, {
                              label: _ctx.l("setup-questions/slider-max-range"),
                              "label-position": "on-border",
                              type: { "is-danger": $data.editQuestionSliderMinMaxError }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_input, {
                                  modelValue: $data.editQuestionSliderMax,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.editQuestionSliderMax) = $event)),
                                  modelModifiers: { number: true },
                                  type: "number",
                                  min: "0",
                                  required: "",
                                  disabled: $data.editQuestionId ? true : false
                                }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label", "type"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["type", "message"])
                      ])
                    ], 64 /* STABLE_FRAGMENT */))
                  : ($data.editQuestionType === "value")
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createCommentVNode(" Applies to free text, number and personal sales questions")
                      ], 64 /* STABLE_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_b_button, {
              type: "is-light",
              rounded: "",
              onClick: _cache[6] || (_cache[6] = $event => ($options.closeQuestionForm())),
              loading: $data.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["loading"]),
            _createVNode(_component_b_button, {
              class: "ml-5",
              type: "is-primary",
              rounded: "",
              "native-type": "submit",
              loading: $data.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["loading"])
          ])
        ], 32 /* NEED_HYDRATION */))
      : _createCommentVNode("v-if", true),
    ($data.editQuestionFormOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███████╗ ██████╗ ██████╗ ███╗   ███╗"),
    _createCommentVNode(" ██╔════╝██╔═══██╗██╔══██╗████╗ ████║"),
    _createCommentVNode(" █████╗  ██║   ██║██████╔╝██╔████╔██║"),
    _createCommentVNode(" ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║"),
    _createCommentVNode(" ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║"),
    _createCommentVNode(" ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝"),
    _createCommentVNode(""),
    _createCommentVNode(" Form bar (edit form name, add new question -button, change question order)"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createElementVNode("div", _hoisted_19, [
      ($data.editFormNameActive === false)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_20, _toDisplayString($options.form.name), 1 /* TEXT */),
            _createVNode(_component_b_tooltip, {
              label: _ctx.l("setup-questions/add-new-question"),
              type: "is-white"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  class: "icon-button",
                  type: "is-light",
                  size: "is-large",
                  onClick: _cache[8] || (_cache[8] = $event => ($options.openQuestionForm())),
                  disabled: $data.loading
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("+")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[9] || (_cache[9] = $event => ($options.openEditFormName()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('setup-questions/change-form-name')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[10] || (_cache[10] = $event => ($options.openChangeQuestionsOrderModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('setup-ordering/change-order')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_b_field, { grouped: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_input, {
                    expanded: "",
                    modelValue: $data.editedFormName,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.editedFormName) = $event)),
                    size: "is-small",
                    "custom-class": "",
                    placeholder: _ctx.l("setup-questions/form-name")
                  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("p", _hoisted_24, [
                _createVNode(_component_b_button, {
                  size: "is-small",
                  type: "is-primary",
                  onClick: _cache[12] || (_cache[12] = $event => ($options.saveForm())),
                  disabled: $data.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_25, [
                _createVNode(_component_b_button, {
                  size: "is-small",
                  onClick: _cache[13] || (_cache[13] = $event => ($options.closeEditFormName())),
                  disabled: $data.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ])
            ])
          ], 64 /* STABLE_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createVNode(_component_b_field, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_checkbox, {
            ref: "publicQuestionnareCheckbox",
            modelValue: $options.form.public_questionnare_enabled === true ? true : false,
            onInput: _cache[14] || (_cache[14] = (evt) => { $options.saveForm(evt.target.checked) })
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("Enable public questionnare")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode("  ██████╗ ██╗   ██╗███████╗███████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗"),
    _createCommentVNode(" ██╔═══██╗██║   ██║██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝"),
    _createCommentVNode(" ██║   ██║██║   ██║█████╗  ███████╗   ██║   ██║██║   ██║██╔██╗ ██║███████╗"),
    _createCommentVNode(" ██║▄▄ ██║██║   ██║██╔══╝  ╚════██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║"),
    _createCommentVNode(" ╚██████╔╝╚██████╔╝███████╗███████║   ██║   ██║╚██████╔╝██║ ╚████║███████║"),
    _createCommentVNode("  ╚══▀▀═╝  ╚═════╝ ╚══════╝╚══════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(" Questions list with edit/delete -buttons"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createElementVNode("div", _hoisted_27, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questions, (question) => {
        return (_openBlock(), _createElementBlock("div", {
          key: question._id,
          style: {"flex":"33.33%","flex-grow":"0"}
        }, [
          ($options.isSupportedQuestionType(question.type))
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                (question.type === "multiple" || question.type === "multiple_many")
                  ? (_openBlock(), _createBlock(_component_b_field, {
                      key: 0,
                      label: question.description,
                      "label-position": "on-border",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_29, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.multiple_selection_strings, (option, idx) => {
                              return (_openBlock(), _createBlock(_component_b_field, {
                                class: "is-flex-grow-1",
                                key: idx
                              }, {
                                default: _withCtx(() => [
                                  (question.type === "multiple_many")
                                    ? (_openBlock(), _createBlock(_component_b_checkbox, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(option), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */))
                                    : (question.type === "multiple")
                                      ? (_openBlock(), _createBlock(_component_b_radio, {
                                          key: 1,
                                          name: `question_${question._id}`,
                                          "native-value": option
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(option), 1 /* TEXT */)
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name", "native-value"]))
                                      : _createCommentVNode("v-if", true)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            }), 128 /* KEYED_FRAGMENT */)),
                            (question.required)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_30, "* " + _toDisplayString(_ctx.l('setup-questions/required-question')), 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr(question.type)), 1 /* TEXT */)
                        ])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]))
                  : (question.type === "slider")
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        _createVNode(_component_b_field, {
                          label: question.description,
                          "label-position": "on-border",
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_33, [
                                _createVNode(_component_b_field, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_slider, {
                                      class: "mt-1",
                                      min: Number(question.multiple_selection_strings[0]),
                                      max: Number(question.multiple_selection_strings[1]),
                                      value: Math.floor(question.multiple_selection_strings[1] / 2)
                                    }, null, 8 /* PROPS */, ["min", "max", "value"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */),
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("div", _hoisted_35, [
                                    _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.l('generic/min')) + ": " + _toDisplayString(question.multiple_selection_strings[0]), 1 /* TEXT */)
                                  ]),
                                  _createElementVNode("div", _hoisted_37, [
                                    _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.l('generic/max')) + ": " + _toDisplayString(question.multiple_selection_strings[1]), 1 /* TEXT */)
                                  ])
                                ]),
                                (question.required)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_39, "* " + _toDisplayString(_ctx.l('setup-questions/required-question')), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true)
                              ]),
                              _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr(question.type)), 1 /* TEXT */)
                            ])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                      ]))
                    : (question.type === "value" && question.quantitative_type === "normal")
                      ? (_openBlock(), _createBlock(_component_b_field, {
                          key: 2,
                          class: "is-flex-grow-1",
                          label: question.description,
                          "label-position": "on-border",
                          message: _ctx.l("generic/type") + ": " + $options.getQuestionTypeStr(question.type)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              placeholder: _ctx.l("generic/answer"),
                              expanded: ""
                            }, null, 8 /* PROPS */, ["placeholder"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "message"]))
                      : (question.type === "number")
                        ? (_openBlock(), _createBlock(_component_b_field, {
                            key: 3,
                            class: "is-flex-grow-1",
                            label: question.description,
                            "label-position": "on-border",
                            message: _ctx.l("generic/type") + ": " + $options.getQuestionTypeStr(question.type)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_input, {
                                placeholder: _ctx.l("generic/answer"),
                                expanded: "",
                                type: "number"
                              }, null, 8 /* PROPS */, ["placeholder"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "message"]))
                        : (question.type === "value" && question.quantitative_type === "quantitative_dotsize")
                          ? (_openBlock(), _createBlock(_component_b_field, {
                              key: 4,
                              class: "is-flex-grow-1",
                              label: question.description,
                              "label-position": "on-border",
                              message: _ctx.l("generic/type") + ": " + $options.getQuestionTypeStr("personal_sales")
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_input, {
                                  placeholder: _ctx.l("generic/answer"),
                                  expanded: "",
                                  type: "number"
                                }, null, 8 /* PROPS */, ["placeholder"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "message"]))
                          : _createCommentVNode("v-if", true),
                _createVNode(_component_b_button, {
                  class: "icon-button ml-1",
                  type: "is-light",
                  size: "is-small",
                  "icon-right": "pencil-outline",
                  onClick: $event => ($options.openQuestionForm(question)),
                  disabled: $data.loading
                }, null, 8 /* PROPS */, ["onClick", "disabled"]),
                _createVNode(_component_b_button, {
                  class: "icon-button mr-5",
                  type: "is-light",
                  size: "is-small",
                  "icon-right": "delete-outline",
                  onClick: $event => ($options.deleteQuestionConfirmModal(question)),
                  disabled: $data.loading
                }, null, 8 /* PROPS */, ["onClick", "disabled"])
              ]))
            : _createCommentVNode("v-if", true),
          ((question.type === "value" || question.type === "number") && question.required)
            ? (_openBlock(), _createElementBlock("p", _hoisted_41, "* " + _toDisplayString(_ctx.l('setup-questions/required-question')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createVNode(_component_b_loading, {
        "is-full-page": false,
        modelValue: $data.loading,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($data.loading) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteQuestionConfirmModalActive,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => (($data.deleteQuestionConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteQuestion
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("setup-questions/confirm-delete-question-text", $data.questionToBeDeleted.description)
            }, null, 8 /* PROPS */, _hoisted_44),
            _createElementVNode("div", _hoisted_45, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[16] || (_cache[16] = $event => ($options.closeDeleteQuestion()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[17] || (_cache[17] = $event => ($options.deleteQuestion()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.changeSortOrderModalActive,
      "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => (($data.changeSortOrderModalActive) = $event)),
      "has-modal-card": false,
      "trap-focus": true,
      "can-cancel": ["escape", "outside"]
    }, {
      default: _withCtx((props) => [
        _createVNode(_component_change_sort_order_modal, {
          type: "questions",
          items: $options.questions,
          onClose: props.close
        }, null, 8 /* PROPS */, ["items", "onClose"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}